<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel ? navbarTitel : 'Notenstruktur'"
            ></head-menu>
            <div class="row mt-2">
              <div class="ml-4 mr-2">
                <button
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  {{ $t("global.save") }}
                </button>
              </div>
              <div class="mr-2">
                <button class="btn btn-primary">
                  <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
                  Ausgabe
                </button>
              </div>
              <div class="mr-2">
                <b-dropdown variant="primary">
                  <template slot="button-content">
                    <font-awesome-icon icon="fa-regular fa-cog" class="mr-2" />
                    <em>Aktionen</em>
                  </template>

                  <b-dropdown-item @click="oeffneLoeschenModal">
                    <font-awesome-icon
                      icon="fa-duotone fa-trash"
                      class="mr-2"
                    />
                    Löschen
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="mr-2">
                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="row p-0">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <v-select
                          v-model="status"
                          class="mt-2 mb-3"
                          label="bezeichnung"
                          :options="notenstrukturstatus"
                          :reduce="(s) => s.id"
                          placeholder="Status Notenstruktur"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label class="p-0 t-5">Status Notenstruktur</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 mt-2">
                  <div class="form-group">
                    <input
                      v-model.trim="sortierung"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Sortierung</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 mb-3">
                  <div class="form-group">
                    <input
                      v-model.trim="kuerzel"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.shortdesignation") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="bezeichnung"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>{{ $t("global.designation") }}</label>
                  </div>
                </div>
              </div>
              <div class="row my-2">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="bezeichnung_m"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Bezeichnung männlich</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="bezeichnung_f"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Bezeichnung weiblich</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <date-picker
                      class="mt-2 mb-3"
                      label="Gültig ab"
                      date-format="dd.mm.yy"
                      :initial="gueltig_ab"
                      placeholder=" "
                      :show-icon="true"
                      @update="(val) => (gueltig_ab = val)"
                    />
                  </div>
                </div>
                <div class="col-xl-6 mt-2">
                  <div class="form-group">
                    <input
                      v-model.trim="ausbildung"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Ausbildung</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
        <!-- START Zuordnung -->
        <container-headline headline="Zuordnung" :col="6"></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div class="row p-0">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <v-select
                          v-model="parent_notenstruktur"
                          class="mt-2 mb-3"
                          label="bezeichnung"
                          :options="parent_notenstrukturen"
                          :reduce="(p) => p.id"
                          placeholder="Übergeordnete Notenstruktur"
                        >
                          <span slot="no-options">{{
                            $t("global.taptosearch")
                          }}</span>
                        </v-select>
                        <label class="p-0 t-5"
                          >Übergeordnete Notenstruktur</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 bg-danger">
                  <div class="form-group">
                    <v-select
                      v-model="report"
                      class="mt-2 mb-3 border-warning"
                      label="bezeichnung"
                      :options="reports"
                      :reduce="(r) => r.id"
                      placeholder="Report"
                    >
                      <span slot="no-options">{{
                        $t("global.taptosearch")
                      }}</span>
                    </v-select>
                    <label class="p-0 t-5 text-warning">Report</label>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="d-flex justify-content-between">
                    <div class="px-1">Sichtbar in Report</div>
                    <div class="px-1">
                      <input
                        v-model.trim="sichtbar_in_report"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="d-flex justify-content-between">
                    <div class="px-1">Struktur in Export ausblenden</div>
                    <div class="px-1">
                      <input
                        v-model.trim="struktur_in_export_versteckt"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="d-flex justify-content-between">
                    <div class="px-1">Unterstruktur in Export ausblenden</div>
                    <div class="px-1">
                      <input
                        v-model.trim="unterstruktur_in_export"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Zuordnung -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START Rechte Seite -->
      <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Berechnung -->
        <container-headline headline="Berechnung" :col="6"></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row mt-2">
                <div class="col-xl-6 mb-3">
                  <div class="form-group">
                    <input
                      v-model.trim="rundung"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Rundung</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="gewichtung"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Gewichtung</label>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="noten_min"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Noten-Minimum</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="noten_max"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Noten-Maximum</label>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="d-flex justify-content-between">
                    <div class="px-1">Pflichtfach</div>
                    <div class="px-1">
                      <input v-model.trim="pflichtfach" type="checkbox" />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="d-flex justify-content-between">
                    <div class="px-1">Versteckter Knoten</div>
                    <div class="px-1">
                      <input
                        v-model.trim="versteckter_knoten"
                        type="checkbox"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Berechnung -->
        <!-- START Punkte -->
        <container-headline headline="Punkte" :col="6"></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row mt-2">
                <div class="col-xl-6 mb-3">
                  <div class="form-group">
                    <input
                      v-model.trim="max_punkte"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Maximum Punkte</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model.trim="rundung_prozent"
                      class="form-control"
                      placeholder=" "
                    />
                    <label>Rundung (%)</label>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="d-flex justify-content-between">
                    <div class="px-1">Punkte summieren</div>
                    <div class="px-1">
                      <input v-model.trim="punkteSummieren" type="checkbox" />
                    </div>
                  </div>
                </div>
                <div class="col-xl-6"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Punkte -->
      </div>
      <!-- ENDE Rechte Seite -->
      <div v-if="id" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 0 }"
                  :to="{
                    name: 'NotenstrukturMitIdUndAnzeige',
                    params: { id: id, anzeige: 0 },
                  }"
                  >Untergeordnete Notenstruktur</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 1 }"
                  :to="{
                    name: 'NotenstrukturMitIdUndAnzeige',
                    params: { id: id, anzeige: 1 },
                  }"
                  >Notenstruktur-Fach</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige === 2 }"
                  :to="{
                    name: 'NotenstrukturMitIdUndAnzeige',
                    params: { id: id, anzeige: 2 },
                  }"
                  >Promotionsregeln</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-xl-12 p-0">
            <div class="tab-container p-3">
              <div v-show="anzeige === 0">
                <!-- Start Notenstruktur -->
                <notenstrukturliste :notenstruktur="id" :shown="anzeige == 0" />
                <!-- Ende Notenstruktur -->
              </div>
              <div v-show="anzeige === 1">
                <!-- Start Notenstruktur-Fach -->
                <notenstruktur-fach :notenstruktur="id" :shown="anzeige == 1" />
                <!-- Ende Notenstruktur-Fach -->
              </div>
              <div v-show="anzeige === 2">
                <!-- Start Promotionsregeln -->
                <promotionsregelliste
                  :notenstruktur="id"
                  :shown="anzeige == 2"
                />
                <!-- Ende Promotionsregeln -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Löschen Modal -->
    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />Notenstruktur löschen
            </h4>
          </div>
          <div class="modal-body">
            Wollen sie den Notenstruktur wirklich löschen?
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>

<script>
import Notenstrukturliste from "@/components/Reiter/Notenstrukturliste";
import NotenstrukturFach from "@/components/Reiter/NotenstrukturFach";
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import DatePicker from "@/components/Datepicker";
import Navbar from "@/components/Navbar";
import LoadingOverlay from "@/components/global/LoadingOverlay";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import Promotionsregelliste from "@/components/Reiter/Promotionsregelliste.vue";

export default {
  name: "Notenstruktur",
  components: {
    NotenstrukturFach,
    HeadMenu,
    Navbar,
    ContainerHeadline,

    DatePicker,
    LoadingOverlay,
    Notenstrukturliste,
    Promotionsregelliste,
  },
  metaInfo() {
    const notenstruktur = this.notenstruktur;
    return {
      titleTemplate: () => {
        if (!notenstruktur) {
          return "Neue Notenstruktur";
        }
        return `Notenstruktur`;
      },
    };
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      notenstruktur: null,
      promotionsregel: null,
      promotionsregeln: [],
      report: null,
      reports: [],
      kuerzel: null,
      status: null,
      notenstrukturstatus: [],
      bezeichnung: null,
      bezeichnung_m: null,
      bezeichnung_f: null,
      gueltig_ab: null,
      ausbildung: null,
      parent_notenstruktur: null,
      parent_notenstrukturen: [],
      rundung: null,
      noten_min: null,
      noten_max: null,
      gewichtung: null,
      max_punkte: null,
      rundung_prozent: null,
      sortierung: null,
      sichtbar_in_report: null,
      punkteSummieren: null,
      struktur_in_export_versteckt: null,
      unterstruktur_in_export: null,
      startdatum: null,
      ende: null,
      pflichtfach: null,
      versteckter_knoten: null,
      startperiode: null,
      anzahlPerioden: null,
      aktuellePeriode: null,
      parallelklasse: null,
      kuerzelParallelklasse: null,
      basiertAuf: null,
      beschreibung: null,
      loading: false,
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.notenstruktur) {
        return `Notenstruktur`;
      } else {
        return "Neuer Notenstruktur";
      }
    },
  },
  created() {
    Api.get("notenstruktur/", { params: { status: "174E30E69C8" } }).then(
      (response) => (this.parent_notenstrukturen = response.data)
    );
    Api.get("notenstrukturstatus/").then(
      (response) => (this.notenstrukturstatus = response.data)
    );
    if (this.id) {
      Api.get("notenstruktur/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeNotenstruktur(response.data);
        }
      );
    }
  },
  mounted: function () {},
  methods: {
    abbrechen() {
      this.$router.push({ name: "Notenstrukturliste" });
    },
    initializeNotenstruktur(notenstruktur) {
      if (notenstruktur) {
        this.notenstruktur = notenstruktur;
        this.kuerzel = notenstruktur.kuerzel;
        this.status = notenstruktur.notenstrukturstatus;
        this.bezeichnung = notenstruktur.bezeichnung;
        this.bezeichnung_m = notenstruktur.bezeichnung_m;
        this.bezeichnung_f = notenstruktur.bezeichnung_f;
        this.gueltig_ab = notenstruktur.gueltig_ab;
        this.noten_min = notenstruktur.noten_min;
        this.noten_max = notenstruktur.noten_max;
        this.pflichtfach = notenstruktur.pflichtfach;
        this.versteckter_knoten = notenstruktur.versteckter_knoten;
        this.ausbildung = notenstruktur.ausbildung;
        this.max_punkte = notenstruktur.max_punkte;
        this.rundung_prozent = notenstruktur.rundung_prozent;
        this.sichtbar_in_report = notenstruktur.sichtbar_in_report;
        this.struktur_in_export_versteckt =
          notenstruktur.struktur_in_export_versteckt;
        this.unterstruktur_in_export = notenstruktur.unterstruktur_in_export;
        this.rundung = notenstruktur.rundung;
        this.parent_notenstruktur =
          notenstruktur.parent_notenstruktur?.kuerzel +
          " / " +
          notenstruktur.parent_notenstruktur?.bezeichnung +
          " / " +
          notenstruktur.parent_notenstruktur?.gueltig_ab;
        this.gewichtung = notenstruktur.gewichtung;
        this.sortierung = notenstruktur.sortierung;
        this.startdatum = notenstruktur.startdatum;
        this.ende = notenstruktur.enddatum;
        this.beschreibung = notenstruktur.beschreibung;
      }
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = {
        kuerzel: this.kuerzel,
      };

      if (!this.notenstruktur) {
        Api.post("notenstruktur/", json)
          .then((response) => {
            this.initializeNotenstruktur(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Notenstruktur erfolgreich gespeichert.",
            });

            this.$router.replace({
              name: "NotenstrukturMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("notenstruktur/", json, {
          params: { id: this.notenstruktur.id },
        })
          .then((response) => {
            this.initializeNotenstruktur(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: "Notenstruktur erfolgreich gespeichert.",
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("notenstruktur/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Notenstrukturliste" });
      });
    },
    oeffneNotenstrukturStartenModal() {
      $("#modal-notenstruktur-starten").modal("show");
    },
    schliesseNotenstrukturStartenModal() {
      $("#modal-notenstruktur-starten").modal("hide");
    },
    notenstrukturStarten() {},
  },
};
</script>

<style></style>
